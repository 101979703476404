import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Section from "../../components/section"
import * as styles from "./page.module.scss"

export default function PageTemplate({ data: { page } }) {
  return (
    <>
      <Seo title={page.frontmatter.title} />
      <Layout>
        <Section>
          <div className={styles.container}>
            <Title headingLevel="h2">{page.frontmatter.title}</Title>
            <div className={styles.content}>
              <MDXRenderer>{page.body}</MDXRenderer>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      body
    }
  }
`
